import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    max-width: 90vw;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius.main};
    background: ${({ theme }) => theme.color.white100};
    font-family: ${({ theme }): string => theme.font.family};
`;

export const HeaderContainer = styled.div`
    padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[3]}
        ${({ theme }) => theme.spacing[4]};
    border-radius: ${({ theme }) => theme.radius.main} ${({ theme }) => theme.radius.main} 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const HeaderLeftContainer = styled.div<{ alignLeft?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
    align-items: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};
`;

HeaderContainer.displayName = 'ModalHeader';

export const BodyContainer = styled.div`
    flex: 1;
    margin: ${({ theme }) => theme.spacing[4]};
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    white-space: pre-line;
`;
BodyContainer.displayName = 'ModalBody';

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${({ theme }) => theme.spacing[4]};
    border-radius: 0 0 ${({ theme }) => theme.radius.main} ${({ theme }) => theme.radius.main};
`;
FooterContainer.displayName = 'ModalFooter';

export const ErrorMessage = styled(Text)`
    color: ${({ theme }) => theme.color.bloodyMary};
`;
export const ConflictModalDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

export const ConflictModalErrorMessage = styled(Text)`
    color: ${({ theme }) => theme.color.black300};
`;

export const ConflictModalErrorDescription = styled(Text)`
    font-size: ${({ theme }) => theme.font.size.large};
`;
