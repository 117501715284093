import styled from '@emotion/styled';
import { SvyButton } from '@/components/savory/SvyButton';

export const FormContent = styled.form`
    display: flex;
    flex-direction: column;
`;

export const ActionBar = styled.div`
    margin-top: ${({ theme }) => theme.spacing[3]};
    display: flex;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const CancelButton = styled(SvyButton)``;

export const FormGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[3]};
    > [data-columns='2'] {
        grid-column: 1 / 3;
    }
    flex-grow: 1;
`;

export const FormSubGrid = styled(FormGrid)`
    overflow-y: visible;
`;
