import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { GuestGroupWithGuestsCount, PricingRule } from '@/services/innovorder/guestGroup/guestGroup.type';
import { GuestGroupsByDepthKey } from './types';

const getGroupTypeByKey = (depth?: GuestGroupsByDepthKey): string => {
    if (depth === GuestGroupsByDepthKey.SECTIONS) return 'section';
    if (depth === GuestGroupsByDepthKey.SUB_SECTIONS) return 'subsection';
    return 'group';
};

export const getGroupsTableColumnsVM = (intl: IntlShape, depth?: GuestGroupsByDepthKey): Column<TableRow>[] => [
    ...(depth === GuestGroupsByDepthKey.SUB_SECTIONS || depth === GuestGroupsByDepthKey.SECTIONS
        ? [
              {
                  Header: computeText(intl, 'group.name'),
                  accessor: 'parentGroupName',
              },
          ]
        : []),
    ...(depth === GuestGroupsByDepthKey.SUB_SECTIONS
        ? [
              {
                  Header: computeText(intl, 'section.name'),
                  accessor: 'parentSectionName',
              },
          ]
        : []),
    {
        Header: computeText(intl, `${getGroupTypeByKey(depth)}.name`),
        accessor: 'name',
    },
    {
        Header: computeText(intl, 'group.pricingRules'),
        accessor: 'pricingRules',
    },
    {
        Header: computeText(intl, 'group.guestsCount'),
        accessor: 'guestsCount',
    },
    {
        Header: computeText(intl, 'group.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];

const parsePricingRulesNames = (pricingRules: PricingRule[]) => {
    return pricingRules.map((pricingRule) => pricingRule.code).join('; ');
};

const parseGuestGroupIdField = (guestGroupId: number): TableCell => {
    return {
        type: 'identifier',
        value: guestGroupId,
    };
};

const parseDepthField = (depth: number): TableCell => {
    let depthStrValue: GuestGroupsByDepthKey;

    if (depth === 0) {
        depthStrValue = GuestGroupsByDepthKey.GROUPS;
    } else if (depth === 1) {
        depthStrValue = GuestGroupsByDepthKey.SECTIONS;
    } else {
        depthStrValue = GuestGroupsByDepthKey.SUB_SECTIONS;
    }

    return {
        type: 'string',
        value: {
            text: depthStrValue,
        },
        style: { display: 'none' },
    };
};

const parseGroupNameField = (groupName: string): TableCell => {
    return {
        type: 'string',
        value: {
            text: groupName,
        },
    };
};

const parseGroupPricingRulesField = (pricingRules: PricingRule[]): TableCell => {
    return {
        type: 'string',
        value: {
            text: parsePricingRulesNames(pricingRules),
        },
    };
};

const parseGuestsCountField = (guestsCount: number, intl: IntlShape): TableCell => {
    return {
        type: 'string',
        value: {
            text: `${guestsCount} ${computeText(intl, 'group.guestsCountLabel')}`,
        },
    };
};

const parseGroupNameAction = (
    guestGroup: GuestGroupWithGuestsCount,
    intl: IntlShape,
    onDelete: (guestGroup: GuestGroupWithGuestsCount) => void,
): TableCell => {
    return {
        type: 'actionList',
        value: [
            {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(guestGroup),
            },
        ],
    };
};

const mapGuestGroupToTableRow = (
    guestGroup: GuestGroupWithGuestsCount,
    intl: IntlShape,
    onDelete: (guestGroup: GuestGroupWithGuestsCount) => void,
): TableRow => {
    return {
        guestGroupId: parseGuestGroupIdField(guestGroup.guestGroupId),
        depth: parseDepthField(guestGroup.depth),
        name: parseGroupNameField(guestGroup.name),
        pricingRules: parseGroupPricingRulesField(guestGroup.pricingRules),
        guestsCount: parseGuestsCountField(guestGroup.guestsCount, intl),
        action: parseGroupNameAction(guestGroup, intl, onDelete),
        parentGroupName: parseGroupNameField(guestGroup.parentGroupName || ''),
        parentSectionName: parseGroupNameField(guestGroup.parentSectionName || ''),
    };
};

export const getGroupsTableRowsVM = (
    intl: IntlShape,
    onDelete: (guestGroup: GuestGroupWithGuestsCount) => void,
    guestGroups?: GuestGroupWithGuestsCount[],
): TableRow[] => {
    if (!guestGroups) {
        return [];
    }
    return guestGroups.map((guestGroup) => mapGuestGroupToTableRow(guestGroup, intl, onDelete));
};

export const canGroupBeDeleted = (guestGroup?: Pick<GuestGroupWithGuestsCount, 'guestsCount'> | null): boolean =>
    !!guestGroup && guestGroup.guestsCount <= 0;

export const formatGroupSectionKey = (key: string): string => {
    return key.toLowerCase().slice(0, -1).replace(/_/g, '');
};
