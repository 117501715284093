import isBoolean from 'lodash/isBoolean';
import { LaunchDarklyState } from '.';

export const castFlag = (flag: unknown | undefined): boolean => {
    if (!isBoolean(flag)) {
        return false;
    }

    return flag;
};

export const castLDFlags = (flags: Record<string, boolean | undefined>): LaunchDarklyState => {
    return {
        isInitialized: true,
        usePosMenuFastImport: castFlag(flags?.usePosMenuFastImport),
        useAssociatedPricingRules: castFlag(flags?.useAssociatedPricingRules),
        useModuleMarketplaceInUbereatsConfig: castFlag(flags?.useModuleMarketplaceInUbereatsConfig),
        useNewEditMenuPages: castFlag(flags?.useNewEditMenuPages),
        useOpsBulkTools: castFlag(flags?.useOpsBulkTools),
        useNewPaymentMethodsPage: castFlag(flags?.useNewPaymentMethodsPage),
        useNewCustomerPage: castFlag(flags?.useNewCustomerPage),
        useIoAnalyticsMobileDashboard: castFlag(flags?.useIoAnalyticsMobileDashboard),
        useNewGuestJourneyDesign: castFlag(flags?.useNewGuestJourneyDesign),
        useCustomerFormAutoGenerateEmailAndPassword: castFlag(flags?.useCustomerFormAutoGenerateEmailAndPassword),
        useNewGuestJourneyDesignWithSectionAndSubSection: castFlag(
            flags?.useNewGuestJourneyDesignWithSectionAndSubSection,
        ),
        useGenerateOrdersReportAsynchronously: castFlag(flags?.useGenerateOrdersReportAsynchronously),
        useEwalletExport: castFlag(flags?.useEwalletExport),
        useReportsV2: castFlag(flags?.useReportsV2),
        useMigratedPaygreenV3: castFlag(flags?.useMigratedPaygreenV3),
        useIopayKycInterface: castFlag(flags?.useIopayKycInterface),
        useBillingSpace: castFlag(flags?.useBillingSpace),
        useCrossSelling: castFlag(flags?.useCrossSelling),
        useCollectiveCateringSalesSummary: castFlag(flags?.useCollectiveCateringSalesSummary),
        useOptionIncludeDepositsInTurnover: castFlag(flags?.useOptionIncludeDepositsInTurnover),
        useAiMenuScan: castFlag(flags?.useAiMenuScan),
        useShift: castFlag(flags?.useShift),
        useNewChannelsPage: castFlag(flags?.useNewChannelsPage),
        useNewBrandUserPermissionPage: castFlag(flags?.useNewBrandUserPermissionPage),
        useNewUsersPage: castFlag(flags?.useNewUsersPage),
        useNewBrandUserPermissionForPasswordChange: castFlag(flags?.useNewBrandUserPermissionForPasswordChange),
        useNewBrandsPage: castFlag(flags?.useNewBrandsPage),
        useNewAnalyticsCommercialRealtimeBoard: castFlag(flags?.useNewAnalyticsCommercialRealtimeBoard),
        useSalesSummaryExportWithTaxExcluded: castFlag(flags?.useSalesSummaryExportWithTaxExcluded),
        usePricingRuleMigration: castFlag(flags?.usePricingRuleMigration),
    };
};
