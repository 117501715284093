import { pick } from 'lodash';
import { GuestGroupWithGuestsCount, GuestGroupMapByDepth } from './guestGroup.type';

export const getGuestGroupMapByDepth = (guestGroups: GuestGroupWithGuestsCount[]): GuestGroupMapByDepth => {
    return guestGroups.reduce((acc, guestGroup) => {
        acc[guestGroup.depth] = acc[guestGroup.depth] || {};
        const children = guestGroups
            .filter(({ parentGuestGroupId }) => parentGuestGroupId === guestGroup.guestGroupId)
            .map(({ guestGroupId }) => guestGroupId);
        acc[guestGroup.depth][guestGroup.guestGroupId] = {
            ...pick(guestGroup, ['guestGroupId', 'name', 'depth', 'parentGuestGroupId']),
            children,
        };
        return acc;
    }, {} as GuestGroupMapByDepth);
};
