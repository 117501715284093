import { Routes } from '@/core/router/routes.types';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { getErrorCode } from '@/utils/errors';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import {
    usePutGuestGroupMutation,
    useGetGuestGroupByIdQuery,
    useGetGuestGroupMapByDepthQuery,
} from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { useGetMinimalPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { getUserToken } from '@/redux/user';
import { routes } from '@/core/router/routes';
import GroupForm from '../GroupForm';
import { useExportGroupsStudents } from '../Groups/useExportGroupsStudents';

type ParamTypes = {
    brandId: string;
    groupId: string;
};
const UpdateGroup: React.FunctionComponent<React.PropsWithChildren> = () => {
    const history = useHistory();
    const { brandId, groupId } = useParams<ParamTypes>();
    const token = useSelector(getUserToken);
    const { data: pricingRulesData } = useGetMinimalPricingRulesQuery({
        brandId: parseInt(brandId, 10),
        token,
    });
    const { data: guestGroupMapByDepth, isLoading: isLoadingGuestGroupMapByDepth } = useGetGuestGroupMapByDepthQuery({
        brandId: parseInt(brandId, 10),
        token,
    });
    const [putGroup, { isSuccess, error, data, isLoading: isLoadingPutGroup }] = usePutGuestGroupMutation();
    const { data: guestGroup, isLoading: isLoadingGuestGroup } = useGetGuestGroupByIdQuery({
        groupId: parseInt(groupId, 10),
        token,
    });
    if (isSuccess && data?.name) {
        history.push(generatePath(Routes.Groups, { brandId }));
    }

    const put = (name: string, pricingRules: number[], parentGuestGroupId?: number | null, depth?: number) => {
        putGroup({
            groupId: parseInt(groupId, 10),
            name,
            pricingRules,
            parentGuestGroupId,
            depth,
            token,
        });
    };
    const { onExport, isLoading: isLoadingExport } = useExportGroupsStudents({ guestGroupMapByDepth });
    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            {guestGroup && (
                <GroupForm
                    group={guestGroup}
                    error={getErrorCode(error)}
                    onSubmit={put}
                    onExport={onExport}
                    isLoading={isLoadingPutGroup || isLoadingGuestGroup || isLoadingGuestGroupMapByDepth}
                    isLoadingExport={isLoadingExport}
                    pricingRules={pricingRulesData || []}
                    guestGroupMap={guestGroupMapByDepth || {}}
                />
            )}
        </RouterNavigationTabs>
    );
};

export default UpdateGroup;
