import { TableRow } from '@/components/atoms/Table/Table.type';
import { Column } from 'react-table';

export enum GuestGroupsByDepthKey {
    GROUPS = 'GROUPS',
    SECTIONS = 'SECTIONS',
    SUB_SECTIONS = 'SUB_SECTIONS',
}
export type GroupsTableRowsByDepth = Record<GuestGroupsByDepthKey, TableRow[]>;
export type GroupsTableColumnsByDepth = Record<GuestGroupsByDepthKey, Column<TableRow>[]>;
