import * as React from 'react';

export enum SVGIcons {
    INFO = 'info',
    INFO_V2 = 'infoV2',
    ACCOUNT = 'account',
    BACK = 'back',
    BRAND = 'brand',
    CHECK = 'check',
    CLIENTS = 'clients',
    CROWN = 'crown',
    DANGER = 'danger',
    DASHBOARD = 'dashboard',
    DISCOUNT = 'discount',
    EXPORTS = 'exports',
    HELP = 'help',
    INTEGRATIONS = 'integrations',
    LANGUAGES = 'languages',
    LOYALTY = 'loyalty',
    ORDERS = 'orders',
    PAYMENTS = 'payments',
    PAYMENT = 'payment',
    REPORTS = 'reports',
    ARROW_FORWARD = 'arrowForward',
    ARROW_BACKWARD = 'arrowBackward',
    MARKETPLACE = 'marketplace',
    NAYAX = 'nayax',
    URSAFF = 'ursaff',
    MEDIA = 'media',
    LOGOUT = 'logout',
    POS = 'pos',
    COLORED_POS = 'coloredPos',
    KIOSK = 'kiosk',
    COLORED_KIOSK = 'coloredKiosk',
    WEB = 'web',
    COLORED_WEB = 'coloredWeb',
    RECEPTION = 'reception',
    KITCHEN_DISPLAY = 'kitchenDisplay',
    DELIVEROO = 'deliveroo',
    JUSTEAT = 'justeat',
    UBEREATS = 'ubereats',
    BUBBLES = 'bubbles',
    CHEVRON = 'chevron',
    DOUBLE_CHEVRON = 'doubleChevron',
    RADIO = 'radio',
    RADIO_CHECKED = 'radioChecked',
    MAGNIFY = 'magnify',
    CALENDAR = 'calendar',
    COLUMN = 'column',
    EYE = 'eye',
    EYE_DISABLED = 'eyeDisabled',
    CROSS = 'cross',
    CROSS_CIRCLED = 'crossCircled',
    MORE_VERTICAL = 'moreVertical',
    HISTORY = 'history',
    PLUS = 'plus',
    TRASH = 'trash',
    CLIPBOARD = 'clipboard',
    PEN = 'pen',
    MEGAPHONE = 'megaphone',
    COLORED_FIDELITE = 'coloredFidelite',
    EWALLET = 'ewallet',
    VENDING_MACHINE = 'vendingMachine',
    CARD = 'card',
    ARROW_RIGHT = 'arrowRight',
}

export type IconProps = {
    size: number;
    color: string;
};

export type SVGIconProps = {
    icon: SVGIcons;
    size?: number;
    color?: string;
    testid?: string;
    rotate?: number;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
};
