import React, { useEffect, useMemo, useState } from 'react';
import {
    BaseGuestGroupAttributes,
    GUEST_GROUP_SHOULD_NOT_BE_UPDATED,
} from '@/services/innovorder/guestGroup/guestGroup.type';
import ConflictModal from '../ConflictModal';

type UpdateModalProps = {
    groupType: string;
    error?: string | null;
    onExport?: () => void;
    isLoadingExport?: boolean;
};

export const UpdateModal: React.FunctionComponent<React.PropsWithChildren<UpdateModalProps>> = ({
    groupType,
    error,
    onExport,
    isLoadingExport,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (error && typeof error === 'string') {
            setIsOpen(true);
        }
    }, [error]);

    const onHide = () => {
        setIsOpen(false);
    };

    const modalProps =
        error !== GUEST_GROUP_SHOULD_NOT_BE_UPDATED
            ? {
                  message: `group.warning.${error}.message`,
                  description: `group.warning.${error}.description`,
                  onCancel: onHide,
              }
            : {
                  message: `${groupType}.modal.conflict.error.message`,
                  description: `${groupType}.modal.conflict.error.description`,
                  onExport,
                  isLoadingExport,
                  onHide,
              };

    return <ConflictModal isOpen={isOpen} {...modalProps} />;
};
