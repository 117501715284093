import React, { useMemo } from 'react';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { StyledText, Container, Header, HeaderLeft, HeaderRight, Subtitle } from './ResultStep.style';

export type SelectPosDeviceStepProps = {
    state?: 'SUCCESS' | 'ERROR';
    message: string;
};

export const ResultStep: React.FunctionComponent<React.PropsWithChildren<SelectPosDeviceStepProps>> = ({
    message,
    state = 'SUCCESS',
}) => {
    const stateText = useMemo(
        () =>
            state === 'SUCCESS' ? 'pricingRule.modal.import.report.success' : 'pricingRule.modal.import.report.error',
        [state],
    );

    return (
        <Container>
            <Header>
                <HeaderLeft>
                    <Title text="pricingRule.modal.import.report.result" />
                    <Subtitle>
                        <Text text={message} />
                    </Subtitle>
                </HeaderLeft>
                <HeaderRight data-testid="section-right-header">
                    <StyledText state={state} type="small" text={stateText} />
                </HeaderRight>
            </Header>
        </Container>
    );
};
