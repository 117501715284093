import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { computeAndTriggerTouchedFields } from '@/utils/form/utils';
import { Input } from '@/components/form/Input';
import { Label } from '@/components/atoms/Label';
import { MultiSelect } from '@/components/form/MultiSelect';
import { SavingBar } from '@/components/atoms/SavingBar';
import { Option } from '@/components/atoms/Select';
import { Select } from '@/components/form/Select';
import { Text } from '@/components/atoms/Text';
import { computeText } from '@/locales/utils';
import { MargedTitle } from '@/pages/Brand/Grant/GrantForm/GrantForm.style';
import {
    BaseGuestGroupAttributes,
    GuestGroupMapByDepth,
    PricingRule,
} from '@/services/innovorder/guestGroup/guestGroup.type';

import { useUrlQueryState } from '@/hooks/routes/useUrlQueryState';
import { ConfirmationBarContainer, Container, FormContainer, SpacedFlex } from './GroupForm.style';
import { GuestGroupsByDepthKey } from '../Groups/types';
import { UpdateModal } from './UpdateModal/UpdateModal';
import { ExportGuestGroup } from '../Groups/useExportGroupsStudents';

type GroupFormProps = {
    group?: BaseGuestGroupAttributes;
    error?: string;
    onSubmit: (name: string, pricingRules: number[], parentGuestGroupId: number | null, depth: number) => void;
    onExport?: (guestGroup: ExportGuestGroup) => void;
    isLoading: boolean;
    isLoadingExport?: boolean;
    pricingRules: PricingRule[];
    guestGroupMap?: GuestGroupMapByDepth;
};

type GuestGroupFormAttributes = {
    name: string;
    pricingRules: Option<number>[];
    parentGroupId?: number | null;
    parentSectionId?: number | null;
    depth: number;
};

const GuestGroupsDepthMapper = {
    [GuestGroupsByDepthKey.GROUPS]: 0,
    [GuestGroupsByDepthKey.SECTIONS]: 1,
    [GuestGroupsByDepthKey.SUB_SECTIONS]: 2,
};

const GroupTypeMapper = ['group', 'section', 'subsection'];

const GroupForm: React.FunctionComponent<React.PropsWithChildren<GroupFormProps>> = ({
    group,
    error,
    onSubmit,
    onExport,
    isLoading,
    isLoadingExport,
    pricingRules,
    guestGroupMap,
}) => {
    const intl = useIntl();
    const [query] = useUrlQueryState();

    const depth = useMemo(() => {
        const stringDepth = query.get('depth');
        return group?.depth || GuestGroupsDepthMapper[stringDepth as keyof typeof GuestGroupsDepthMapper] || 0;
    }, [query, group]);

    const groupType = useMemo(() => GroupTypeMapper[depth], [depth]);

    const mapToPricingRulesSelectOptions = (pricingRules: PricingRule[]): Option<number>[] => {
        if (!pricingRules) return [];
        return pricingRules.map(({ code, pricingRuleId }) => ({ label: code, value: pricingRuleId }));
    };

    const mapToPricingRulesSelectedValues = (selectedValues: number[]): Option<number>[] => {
        if (!pricingRules) return [];
        return pricingRules
            .filter((rule) => selectedValues.includes(rule.pricingRuleId))
            .map(({ code, pricingRuleId }) => ({ label: code, value: pricingRuleId }));
    };

    const initDefaultValues = (): GuestGroupFormAttributes => {
        if (group) {
            const getParentGroupId = () => {
                if (depth === 1) {
                    return group.parentGuestGroupId;
                }
                if (depth === 2 && group.parentGuestGroupId) {
                    return guestGroupMap?.[1]?.[group.parentGuestGroupId]?.parentGuestGroupId ?? null;
                }
                return null;
            };

            const parentGroupId = getParentGroupId();
            const parentSectionId = depth === 2 ? group.parentGuestGroupId : null;

            return {
                depth: group.depth,
                name: group.name,
                pricingRules: mapToPricingRulesSelectedValues(group.pricingRules),
                parentGroupId,
                parentSectionId,
            };
        }
        return {
            name: '',
            pricingRules: [],
            parentGroupId: null,
            parentSectionId: null,
            depth,
        };
    };
    const defaultValues: GuestGroupFormAttributes = initDefaultValues();

    const form = useForm<GuestGroupFormAttributes>({ defaultValues, values: defaultValues });
    const parentGroupId = form.watch('parentGroupId');
    const mapToParentGroupSelectOptions = (): Option<number>[] => {
        if (!guestGroupMap) return [];
        const guestGroupsByDepth = Object.values(guestGroupMap[0] || {}) || [];
        const options = guestGroupsByDepth.map(({ guestGroupId, name }) => ({
            label: name,
            value: guestGroupId,
        }));

        return options;
    };

    const mapToParentSectionSelectOptions = useCallback((): Option<number>[] => {
        if (!guestGroupMap) return [];

        const guestGroupsByDepth = Object.values(guestGroupMap[1] || {}) || [];

        const options = guestGroupsByDepth
            .filter((section) => section.parentGuestGroupId === parentGroupId)
            .map(({ guestGroupId, name }) => ({
                label: name,
                value: guestGroupId,
            }));

        return options;
    }, [parentGroupId, guestGroupMap]);

    const handleExport = useCallback(() => {
        if (group && onExport) {
            onExport(group);
        }
    }, [onExport, group]);

    const finalTouchedFields = computeAndTriggerTouchedFields({}, form.formState.touchedFields, form.trigger);

    return (
        <FormProvider {...form}>
            <Container>
                <div style={{ flex: 1 }}>
                    <MargedTitle text={`${groupType}.tableCard.title`} weight="medium" />
                    <Text text={`${groupType}.tableCard.subtitle`} weight="light" />
                </div>
                <FormContainer data-testid="grant-form">
                    <SpacedFlex>
                        <MargedTitle text="group.tableCard.title" weight="medium" />
                        {depth > 0 && (
                            <div style={{ width: '100%' }}>
                                <Label labelId={'group.name'} inputId={'group.name'} />
                                <Select
                                    name="parentGroupId"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    options={mapToParentGroupSelectOptions()}
                                    placeholder={computeText(intl, 'group.name')}
                                    isTouched={finalTouchedFields.parentGroupId as unknown as boolean}
                                    required={true}
                                    showValidation={true}
                                />
                            </div>
                        )}
                        {depth > 1 && (
                            <div style={{ width: '100%' }}>
                                <Label labelId={'section.name'} inputId={'section.name'} />
                                <Select
                                    name="parentSectionId"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    options={mapToParentSectionSelectOptions()}
                                    placeholder={computeText(intl, 'section.name')}
                                    isTouched={finalTouchedFields.parentSectionId as unknown as boolean}
                                    required={true}
                                    showValidation={true}
                                />
                            </div>
                        )}
                        <Input
                            data-testid="groupForm-nameInput"
                            name="name"
                            rules={{ required: computeText(intl, 'field.required') }}
                            placeholder={`${groupType}.name`}
                            labelId={`${groupType}.name`}
                            isTouched={finalTouchedFields.name}
                            disabled={
                                (depth === 1 && !form.getValues('parentGroupId')) ||
                                (depth === 2 && !form.getValues('parentSectionId'))
                            }
                        />
                        <div style={{ width: '100%' }}>
                            <Label labelId="group.pricingRules" inputId="group.pricingRules" />
                            <MultiSelect
                                inputId="group.pricingRules"
                                isMulti={true}
                                name="pricingRules"
                                rules={{ required: computeText(intl, 'field.required') }}
                                options={mapToPricingRulesSelectOptions(pricingRules)}
                                placeholder={computeText(intl, 'group.pricingRules')}
                                isTouched={finalTouchedFields.pricingRules as unknown as boolean}
                            />
                        </div>
                    </SpacedFlex>
                </FormContainer>
            </Container>
            <ConfirmationBarContainer>
                <SavingBar
                    onSave={form.handleSubmit(({ name, pricingRules, parentGroupId, parentSectionId, depth }) =>
                        onSubmit(
                            name,
                            pricingRules.map(({ value }) => value),
                            parentSectionId ?? parentGroupId ?? null,
                            depth,
                        ),
                    )}
                    loading={isLoading}
                    changesCount={Object.keys(form.formState.dirtyFields).length}
                    onCancel={() => form.reset(defaultValues)}
                />
            </ConfirmationBarContainer>
            <UpdateModal
                isLoadingExport={isLoadingExport}
                error={error}
                groupType={groupType}
                onExport={handleExport}
            />
        </FormProvider>
    );
};

export default GroupForm;
