import React, { useMemo } from 'react';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import ConflictModal from '../../GroupForm/ConflictModal';
import { ExportGuestGroup } from '../useExportGroupsStudents';

type DeleteModalProps = {
    guestGroup: GuestGroupWithGuestsCount | null;
    isLoading: boolean;
    isLoadingExport: boolean;
    canBeDeleted: boolean;
    onHide: () => void;
    onSubmit: () => void;
    onExport: (guestGroup: ExportGuestGroup) => void;
};

export const DeleteModal: React.FunctionComponent<React.PropsWithChildren<DeleteModalProps>> = ({
    guestGroup,
    isLoading,
    isLoadingExport,
    canBeDeleted,
    onSubmit,
    onHide,
    onExport,
}) => {
    const isOpen = useMemo(() => !!guestGroup, [guestGroup]);
    const groupType = useMemo(() => {
        switch (guestGroup?.depth) {
            case 0:
                return 'group';
            case 1:
                return 'section';
            case 2:
                return 'subsection';
            default:
                return 'group';
        }
    }, [guestGroup?.depth]);

    const modalProps = canBeDeleted
        ? {
              message: 'group.modal.confirmDelete',
              onDelete: onSubmit,
              disableDelete: isLoading,
              onCancel: onHide,
          }
        : {
              message: `${groupType}.delete.modal.conflict.error.message`,
              description: `${groupType}.delete.modal.conflict.error.description`,
              onExport: () => guestGroup && onExport(guestGroup),
              isLoadingExport,
              onHide,
          };

    return <ConflictModal isOpen={isOpen} {...modalProps} />;
};
