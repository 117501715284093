import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';

export const StyledText = styled(Text)<{ state: 'SUCCESS' | 'WARNING' | 'ERROR' }>`
    min-width: 80px;
    text-align: center;
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
    border-radius: ${({ theme }) => `${theme.radius.main}`};
    color: ${({ theme }) => theme.color.white100};
    background: ${({ state, theme }) => {
        if (state === 'SUCCESS') {
            return theme.color.green200;
        }

        if (state === 'ERROR') {
            return theme.color.red300;
        }

        return theme.color.yellow200;
    }};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => `${theme.spacing[4]}`};

    overflow-y: auto;
    flex-grow: 1;

    h2 {
        margin-bottom: ${({ theme }) => `${theme.spacing[2]}`};
    }
`;

export const Header = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => `${theme.spacing[4]}`};

    ${({ theme }) => theme.breakpoints.mobile} {
        flex-direction: column;
    }
`;

export const HeaderLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const HeaderRight = styled.div`
    display: flex;
    justify-content: end;
    align-items: start;

    ${({ theme }) => theme.breakpoints.mobile} {
        margin-top: ${({ theme }) => theme.spacing[4]};
        justify-content: start;
    }
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.small};
`;

export const Body = styled.div<{ overflowBody: boolean }>`
    overflow-y: ${({ overflowBody }) => (overflowBody ? 'auto' : 'unset')};
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.mobile} {
        flex-direction: column;
    }
`;
