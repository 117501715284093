export type GuestGroup = {
    guestGroupId: number;
    name: string;
    brandId: number;
    pricingRules: PricingRule[];
    depth: number;
    parentGuestGroupId?: number | null;
    parentGroupName?: string;
    parentSectionName?: string;
};

export type GuestGroupWithGuestsCount = GuestGroup & {
    guestsCount: number;
};

export type GuestGroupMap = Record<
    number,
    Pick<GuestGroup, 'guestGroupId' | 'name' | 'depth' | 'parentGuestGroupId'> & { children: number[] }
>;

export type GuestGroupMapByDepth = Record<number, GuestGroupMap>;

export type PricingRule = {
    pricingRuleId: number;
    code: string;
};

export type GetGuestGroupsPayload = {
    brandId: number;
};

export type GetGuestGroupByIdPayload = {
    groupId: number;
};

export type DeleteGuestGroupPayload = Pick<GuestGroup, 'guestGroupId'>;
export type CreateGuestGroupsPayload = {
    pricingRules: number[];
    name: string;
    brandId: number;
    parentGuestGroupId?: number | null;
    depth?: number;
};

export type UpdateGuestGroupsPayload = {
    pricingRules: number[];
    name: string;
    groupId: number;
    parentGuestGroupId?: number | null;
    depth?: number;
};

export type BaseGuestGroupAttributes = {
    name: string;
    pricingRules: number[];
    parentGuestGroupId?: number | null;
    depth: number;
    brandId: number;
};

export type RelatedGuestsCount = {
    count: number;
};

export enum GuestGroupDepth {
    GROUPS = 0,
    SECTIONS = 1,
    SUB_SECTIONS = 2,
}

export const GUEST_GROUP_SHOULD_NOT_BE_UPDATED = 'guest_group_should_not_be_updated';

export type UploadGuestGroupsPayload = {
    brandId: number;
    formData: FormData;
};

export type UploadGuestGroupsResponse = {
    [key in 'groups' | 'sections' | 'subsections']: {
        created: 0;
        updated: 0;
    };
};

export type UploadGuestGroupsErrorsResponse = {
    line: number;
    field: string;
    message: string;
};
