import React, { useState, useMemo, useCallback } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useUploadGuestGroupsMutation } from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { UploadGuestGroupsErrorsResponse } from '@/services/innovorder/guestGroup/guestGroup.type';
import { getUserToken } from '@/redux/user';
import { useSelector } from 'react-redux';
import { StepData } from '@/components/atoms/Wizard/Wizard';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { UploadStep } from './UploadStep';
import { ResultStep } from './ResultStep';
import { formatMessage } from './UploadModal.utils';

type UploadPricingRulesFormValues = {
    file: File | null;
};

type UploadModalVMProps = {
    onHide: () => void;
    brandId: string;
};

export const useUploadModalVM = ({ onHide, brandId }: UploadModalVMProps) => {
    const intl = useIntl();
    const token = useSelector(getUserToken);
    const [currentStep, setCurrentStep] = useState(0);
    const [uploadGuestGroups, { isLoading, data, error }] = useUploadGuestGroupsMutation();
    const uploadState = useMemo(() => (error ? 'ERROR' : 'SUCCESS'), [error, data]);
    const message = useMemo(() => {
        const uploadErrors = error && 'data' in error ? (error.data as UploadGuestGroupsErrorsResponse[]) : undefined;
        const generalError = error && 'message' in error ? error.message : undefined;
        return formatMessage(intl, data, uploadErrors, generalError);
    }, [data, error, intl]);

    const form: UseFormReturn<UploadPricingRulesFormValues> = useForm<UploadPricingRulesFormValues>({
        mode: 'all',
        defaultValues: {
            file: null,
        },
    });

    const onFormValidate = async () => {
        const { getValues } = form;
        const { file } = getValues();
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        await uploadGuestGroups({
            brandId: Number(brandId),
            formData,
            token,
        });
        setCurrentStep(1);
    };

    const handleHide = useCallback(() => {
        if (!isLoading) {
            setCurrentStep(0);
            onHide();
        }

        if (data) {
            window.location.reload();
        }
        return true;
    }, [isLoading, onHide, setCurrentStep, data]);

    const steps: StepData[] = [
        {
            title: 'group.modal.import.report.title',
            component: <UploadStep brandId={brandId} />,
            showHeader: false,
            showNext: false,
            showValidate: true,
            onValidate: onFormValidate,
        },
        {
            title: 'group.modal.import.report.title',
            component: <ResultStep state={uploadState} message={message} />,
            showHeader: true,
            showValidate: false,
            showPrevious: false,
            cancelLabel: computeText(intl, 'button.ok'),
            onCancel: handleHide,
        },
    ];

    React.useEffect(() => {
        form.watch(['file']);
    }, [form]);

    return {
        form,
        canValidate: form.formState.isValid,
        currentStep,
        steps,
        handleHide,
        isLoading,
        onFormValidate,
    };
};
