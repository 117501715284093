import {
    useGetEntranceFeeFieldSuggestions,
    useGetGrantFieldSuggestions,
} from '@/pages/Customer/PricingConfigurationSection/hooks';

import { EntranceFees } from '@/services/innovorder/entranceFee/entranceFee.type';
import { FormattedMessage } from 'react-intl';
import { Grants } from '@/services/innovorder/grant/grant.type';
import { Input } from '@/components/form/Input';
import { PricingRules } from '@/services/innovorder/pricingRule/pricingRule.type';
import React from 'react';
import { Select } from '@/components/form/Select';
import { getUsePricingRuleMigration } from '@/redux/launchDarkly';
import { useSelector } from 'react-redux';
import { getPricingRulesOptions } from './PricingRuleForm.utils';
import { FlexContainer, WarningMessage } from './PricingRuleForm.style';

type PricingRuleFormProps = {
    grants?: Grants;
    pricingRules?: PricingRules;
    entranceFees?: EntranceFees;
};

const PricingRuleForm: React.FunctionComponent<React.PropsWithChildren<PricingRuleFormProps>> = ({
    grants,
    pricingRules,
    entranceFees,
}) => {
    const grantsFieldSuggestions = useGetGrantFieldSuggestions(grants);
    const entrenceFeeFieldSuggestions = useGetEntranceFeeFieldSuggestions(entranceFees);
    const usePricingRuleMigration = useSelector(getUsePricingRuleMigration);

    return (
        <div>
            <WarningMessage>
                <FormattedMessage
                    id={`customer.student.pricing.rules.${usePricingRuleMigration ? 'deprecated' : 'soon_deprecated'}`}
                    values={{
                        moreInfo: (
                            <a
                                href={`https://help.innovorder.fr/hc/fr/articles/14396587730588-Mise-%C3%A0-jour-de-la-fiche-convives-Les-r%C3%A9gimes-tarifaires`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage id="customer.student.pricing.rules.more_info" />
                            </a>
                        ),
                    }}
                />
            </WarningMessage>
            <FlexContainer>
                <Input
                    labelId="customer.student.pricing.rules.grant.code"
                    name={'grantCode'}
                    suggestions={grantsFieldSuggestions}
                    disabled={usePricingRuleMigration}
                />
                <Input
                    labelId="customer.student.pricing.rules.entrance.fee.code"
                    name={'entranceFeeCode'}
                    suggestions={entrenceFeeFieldSuggestions}
                    disabled={usePricingRuleMigration}
                />
                <Select
                    labelId="customer.student.pricing.rules.code"
                    name={'pricingRuleCode'}
                    options={getPricingRulesOptions(pricingRules || [])}
                />
            </FlexContainer>
        </div>
    );
};

export default PricingRuleForm;
