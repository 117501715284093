import { DraggableLocation } from '@hello-pangea/dnd';
import { PaymentMethod } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { codeDefaultList, ParsedOptions } from '../../constants';

export enum ListTypes {
    ACCEPTED = 'accepted',
    REFUSED = 'refused',
}

export type listsType = {
    [key in ListTypes]: PaymentMethod[];
};

export const getBindedList = (paymentMethods: PaymentMethod[]): PaymentMethod[] => {
    return paymentMethods
        .filter(
            (pm) =>
                codeDefaultList[pm.code] &&
                codeDefaultList[pm.code].isValid() &&
                codeDefaultList[pm.code].channels.some((channelId) => pm.channelId === channelId) &&
                pm.active,
        )
        .sort((a, b) => a.position - b.position);
};

export const getUnbindedList = (paymentMethods: PaymentMethod[], channelId: ChannelId): PaymentMethod[] => {
    const defaultList: PaymentMethod[] = Object.values(codeDefaultList)
        .filter(
            ({ channels, isValid, code }) =>
                isValid() &&
                channels.some((channel: ChannelId) => channel === channelId) &&
                !paymentMethods.some((pm) => pm.code === code),
        )
        .map(({ label, code, parsedOptions }, index, array) => {
            return {
                label,
                active: false,
                channelId,
                position: index + array.length,
                code,
                consumptionModes: [],
                creditNoteAllowed: parsedOptions.includes(ParsedOptions.CREDIT_NOTE_ALLOWED),
                changeNotAllowed: parsedOptions.includes(ParsedOptions.CHANGE_ALLOWED),
            };
        });

    const disabledPaymentMethods: PaymentMethod[] = paymentMethods.filter(
        (pm) =>
            codeDefaultList[pm.code] &&
            codeDefaultList[pm.code].isValid() &&
            codeDefaultList[pm.code].channels.some((channelId) => pm.channelId === channelId) &&
            !pm.active,
    );

    return [...defaultList, ...disabledPaymentMethods].sort((a, b) => a.position - b.position);
};

const reorder = (list: PaymentMethod[], startIndex: number, endIndex: number): PaymentMethod[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (
    source: PaymentMethod[],
    destination: PaymentMethod[],
    start: DraggableLocation,
    end: DraggableLocation,
): listsType => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(start.index, 1);

    destClone.splice(end.index, 0, removed);

    return {
        [ListTypes.ACCEPTED]: start.droppableId === ListTypes.ACCEPTED ? sourceClone : destClone,
        [ListTypes.REFUSED]: start.droppableId === ListTypes.ACCEPTED ? destClone : sourceClone,
    };
};

export const getNewOrder = (
    lists: listsType,
    source: DraggableLocation,
    destination: DraggableLocation,
): PaymentMethod[] => {
    // Re-order in same list
    if (source.droppableId === destination.droppableId) {
        const droppableId = source.droppableId === ListTypes.ACCEPTED ? ListTypes.ACCEPTED : ListTypes.REFUSED;
        const items = reorder(lists[droppableId], source.index, destination.index);
        const orderedData = items.map((pm, position) => {
            const action: 'update' | null =
                droppableId === ListTypes.ACCEPTED && position !== pm.position ? 'update' : null;
            return { ...pm, action, position };
        });
        const unchangedOrder = lists[droppableId === ListTypes.ACCEPTED ? ListTypes.REFUSED : ListTypes.ACCEPTED];
        return [...orderedData, ...unchangedOrder];
    }
    // Switch from binded to unbinded, or opposite
    const sourceDroppableId = source.droppableId === ListTypes.ACCEPTED ? ListTypes.ACCEPTED : ListTypes.REFUSED;
    const destinationDroppableId =
        destination.droppableId === ListTypes.ACCEPTED ? ListTypes.ACCEPTED : ListTypes.REFUSED;
    const movedData = move(lists[sourceDroppableId], lists[destinationDroppableId], source, destination);
    const newBindedData = movedData[ListTypes.ACCEPTED].map((pm, index) => {
        const action: 'create' | 'update' | null = !pm.paymentMethodId
            ? 'create'
            : pm.position !== index || !pm.active
            ? 'update'
            : null;
        return {
            ...pm,
            action,
            position: index,
            active: true,
        };
    });
    const newUnbindedData = movedData[ListTypes.REFUSED].map((pm, index) => {
        const action: 'delete' | null = (pm.paymentMethodId && pm.active) || pm.action === 'delete' ? 'delete' : null;
        return {
            ...pm,
            action,
            position: index,
            active: false,
        };
    });
    return [...newBindedData, ...newUnbindedData];
};
