import { computeText } from '@/locales/utils';
import { IntlShape } from 'react-intl';
import {
    UploadGuestGroupsErrorsResponse,
    UploadGuestGroupsResponse,
} from '@/services/innovorder/guestGroup/guestGroup.type';

export const formatMessage = (
    intl: IntlShape,
    result?: UploadGuestGroupsResponse,
    errors?: UploadGuestGroupsErrorsResponse[],
    generalError?: string,
): string => {
    if (errors) {
        return errors.map((error) => computeText(intl, 'group.modal.import.report.errorMessage', error)).join('\n');
    }
    if (result) {
        return Object.entries(result)
            .map(([key, { created, updated }]) => {
                const message = computeText(intl, `group.modal.import.report.${key}`);
                const successMessage = computeText(intl, `group.modal.import.report.successMessage`, {
                    created,
                    updated,
                });
                return `${message}: \n${successMessage} \n`;
            })
            .join('\n');
    }
    return generalError || '';
};
