import React from 'react';
import { FormattedMessage } from 'react-intl';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { Button } from '@/components/atoms/Button';
import { routes } from '@/core/router/routes';
import { Container, ActionsContainer } from './Groups.Style';
import { canGroupBeDeleted, formatGroupSectionKey } from './utils';
import { UploadModal } from './UploadModal/UploadModal';
import { useGroupMassImport, useGroupsViewModel } from './Group.viewmodel';
import { GuestGroupsByDepthKey } from './types';
import { GroupingSection } from './GroupingSection';
import { DeleteModal } from './DeleteModal';
import { useExportGroupsStudents } from './useExportGroupsStudents';

export const Groups: React.FunctionComponent<React.PropsWithChildren> = () => {
    const {
        brandId,
        groupToDelete,
        guestGroups,
        guestGroupMapByDepth,
        isLoading,
        isLoadingDelete,
        groupsTableRowsByDepth,
        groupsTableColumnsByDepth,
        handleCloseModal,
        handleDeleteGroup,
        handleAddGroup,
        handleOnRowClick,
    } = useGroupsViewModel();
    const { isOpen, handleHide, handleUnHide, handleDownload } = useGroupMassImport({ guestGroups });
    const { onExport, isLoading: isLoadingExport } = useExportGroupsStudents({ guestGroupMapByDepth });

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <UploadModal isOpen={isOpen} onHide={handleHide} brandId={brandId} />
            <Container>
                <ActionsContainer>
                    <Button buttonType="primary" onClick={handleUnHide}>
                        <FormattedMessage id="pricingRule.action.import" />
                    </Button>
                    <Button buttonType="primary" onClick={handleDownload}>
                        <FormattedMessage id="pricingRule.action.export" />
                    </Button>
                </ActionsContainer>
                {Object.keys(groupsTableRowsByDepth).map((key) => (
                    <GroupingSection
                        key={key}
                        titleId={`${formatGroupSectionKey(key)}.tableCard.title`}
                        subtitleId={`${formatGroupSectionKey(key)}.tableCard.subtitle`}
                        actionButtonId={`${formatGroupSectionKey(key)}.tableCard.action`}
                        onActionClick={() => {
                            handleAddGroup(key as GuestGroupsByDepthKey);
                        }}
                        onRowClick={handleOnRowClick}
                        tableProps={{
                            rows: groupsTableRowsByDepth[key as GuestGroupsByDepthKey],
                            columns: groupsTableColumnsByDepth[key as GuestGroupsByDepthKey],
                            isLoading,
                            emptyMessageId: 'group.emptyMessage',
                        }}
                    />
                ))}
                <DeleteModal
                    canBeDeleted={canGroupBeDeleted(groupToDelete)}
                    isLoading={isLoadingDelete}
                    isLoadingExport={isLoadingExport}
                    guestGroup={groupToDelete}
                    onHide={handleCloseModal}
                    onSubmit={handleDeleteGroup}
                    onExport={onExport}
                />
            </Container>
        </RouterNavigationTabs>
    );
};
