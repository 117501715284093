import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import RawModal from '@/components/atoms/RawModal';
import { Title } from '@/components/atoms/Title';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { theme } from '@/core/theme';
import {
    BodyContainer,
    ConflictModalDescription,
    ConflictModalErrorDescription,
    Container,
    FooterContainer,
    HeaderContainer,
    HeaderLeftContainer,
} from './ConflictModal.style';
import Loader from '@/components/atoms/Loader';

export type ConflictModalProps = {
    isOpen: boolean;
    onHide?: () => void;
    onExport?: () => void;
    onDelete?: () => void;
    onCancel?: () => void;
    width?: number;
    height?: number;
    title?: string;
    icon?: SVGIcons;
    iconColor?: string;
    iconSize?: number;
    headerSideElement?: ReactElement;
    shouldNotHideOnClickOutside?: boolean;
    message?: string;
    description?: string;
    isLoading?: boolean;
    isLoadingExport?: boolean;
    disableDelete?: boolean;
};
interface SubComponents {
    Header: typeof HeaderContainer;
    Body: typeof BodyContainer;
    Footer: typeof FooterContainer;
}
const ConflictModal: React.FunctionComponent<ConflictModalProps> & SubComponents = ({
    isOpen,
    onHide,
    onExport,
    onDelete,
    onCancel,
    height,
    shouldNotHideOnClickOutside,
    headerSideElement,
    message,
    description,
    isLoading,
    isLoadingExport,
    disableDelete,
    ...otherProps
}) => {
    return (
        <RawModal isOpen={isOpen} onHide={shouldNotHideOnClickOutside ? undefined : onHide} width={412} height={height}>
            <Container {...otherProps}>
                <HeaderContainer>
                    <HeaderLeftContainer alignLeft={true}>
                        <SVGIcon icon={SVGIcons.INFO_V2} size={30} color={theme.color.bloodyMary} />
                        <Title text={'group.modal.warning'} />
                    </HeaderLeftContainer>
                    {headerSideElement}
                </HeaderContainer>

                {isLoading ? (
                    <BodyContainer>
                        <Loader />
                    </BodyContainer>
                ) : (
                    <>
                        <BodyContainer>
                            <ConflictModalDescription>
                                <Text text={message} />
                                {description && <ConflictModalErrorDescription text={description} />}
                            </ConflictModalDescription>
                        </BodyContainer>

                        <FooterContainer>
                            {onExport && (
                                <Button onClick={onExport} isLoading={isLoadingExport}>
                                    <FormattedMessage id={'group.modal.conflict.export.button'} />
                                </Button>
                            )}
                            {onHide && (
                                <Button onClick={onHide}>
                                    <FormattedMessage id={'button.ok'} />
                                </Button>
                            )}
                            {onCancel && (
                                <Button buttonType={'outlinedPrimary'} onClick={onCancel}>
                                    <FormattedMessage id={'button.cancel'} />
                                </Button>
                            )}
                            {onDelete && (
                                <Button onClick={onDelete} disabled={disableDelete}>
                                    <FormattedMessage id={'button.delete'} />
                                </Button>
                            )}
                        </FooterContainer>
                    </>
                )}
            </Container>
        </RawModal>
    );
};

ConflictModal.Header = HeaderContainer;
ConflictModal.Body = BodyContainer;
ConflictModal.Footer = FooterContainer;
export default ConflictModal;
